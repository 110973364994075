import React, { useContext } from 'react';
import { graphql } from 'gatsby';

import ProductFiltersContext from '@/context/ProductFiltersContext';

import Grid from '@/atoms/Grid';

import CategoryHeader from '@/components/CategoryHeader';
import ProductGrid from '@/components/ProductGrid';
import SchemaOrg from '@/components/SchemaOrg';
import Head from '@/components/Head';

export default ({ data, pageContext, location }) => {
  const { wines } = data.graph;
  const [productFilters] = useContext(ProductFiltersContext);

  return (
    <>
      <Head title={pageContext.pageTitle} canonical={location.pathname} />
      <SchemaOrg
        type="breadcrumbs"
        navigationTags={pageContext.defaultNavigationTags}
      />

      <Grid.Container as="section">
        <CategoryHeader title={pageContext.pageTitle} />

        <ProductGrid
          filters={productFilters}
          products={wines}
          tileSize="small"
          hideSoldOut
        />
      </Grid.Container>
    </>
  );
};

export const query = graphql`
  query($value: [Graph_WineType!]) {
    graph {
      wines(where: { status: PUBLISHED, type_in: $value }, orderBy: sku_ASC) {
        ...WineData
      }
    }
  }
`;
